<template>
	<div>
		<div class="container my-md-5">
			<div class="row">
				<div class="page-content col-12 p-0 px-md-3">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	setup() {},
	components: {}
};
</script>

<style></style>
